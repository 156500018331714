import React, { useState } from 'react';
import styled from 'styled-components';
import AttentionSign from '../../images/AttentionSign.svg';
import CloseMessageIcon from '../../images/CloseMessageIcon.svg';
import useSessionStorage from '../../utils/useSessionStorage';

export const MessageVariant = {
  DANGER: 'danger',
  WARNING: 'warning',
  SUCCESS: 'success',
};

const backgroundByVariant = {
  [MessageVariant.DANGER]: '#D14949',
  [MessageVariant.WARNING]: '#FFE352',
  [MessageVariant.SUCCESS]: '#1EB648',
};

const colorByVariant = {
  [MessageVariant.DANGER]: '#ffffff',
  [MessageVariant.WARNING]: '#343434',
  [MessageVariant.SUCCESS]: '#ffffff',
};

const StyledAttentionSign = styled(AttentionSign)`
  margin-right: 13px;
  fill: ${(props) => colorByVariant[props.$variant]};
`;

const Container = styled.div`
  background: ${(props) => backgroundByVariant[props.$variant]};
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  width: 100%;
  height: 46px;
  color: ${(props) => colorByVariant[props.$variant]};
  font-family: ${(props) => props.theme.mainFont};
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin-bottom: 12px;
  justify-content: space-between;

  & a {
    color: ${(props) => colorByVariant[props.$variant]};
    text-decoration: underline;
  }
`;

const MessageText = styled.div`
  display: flex;
  align-items: center;
`;

export default function StaticSiteMessageRow({
  children,
  variant = MessageVariant.SUCCESS,
  className,
}) {
  
  const [dismissed, setDismissed] = useState(false);
  
  if (!children) {
    return null;
  }

  if (dismissed) {
    return null;
  }
  
  return (
    <Container $variant={variant} className={className}>
      <MessageText>
        <StyledAttentionSign $variant={variant} />
        {children}
      </MessageText>
        <CloseButton $variant={variant} onClick={() => setDismissed(true)} title="Close">
          <CloseMessageIcon />
        </CloseButton>
    </Container>
  );
}

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 16px;
  & svg {
    stroke: ${(props) => colorByVariant[props.$variant]};
  }
`;

export const GlobalMessage = styled(StaticSiteMessageRow)`
  height: 59px;
  margin-bottom: 0;
`;

export function GlobalDismissibleMessageRow({ children, variant }) {
  const [dismissed, setDismissed] = useSessionStorage(
    'maintenance_12_dismissed'
  );
  if (dismissed) {
    return null;
  }

  return (
    <GlobalMessage variant={variant} onClose={() => setDismissed(true)}>
      {children}
    </GlobalMessage>
  );
}
